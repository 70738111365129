//exports.baseAPIURL = 'http://localhost:3000/api/';
//exports.baseAPIURL = 'http://52.47.98.202:3000/api/';
exports.baseAPIURL = 'http://fb-rencontre.fr:3000/api/';

//exports.rootURL = 'http://localhost:3000/';
//exports.rootURL = 'http://52.47.98.202:3000/';
exports.rootURL = 'http://fb-rencontre.fr:3000/';
exports.secretOrKey = "hGXoNRl8ukug48pCfY2p";

exports.subscriptionProducts = {
    'com.instaswipey.FreeTrial.InstaswipeyAutoRenewableSubscriptionByMonth': {
        price: 5,
        period: 'month',
    },
    'com.instaswipey.FreeTrial.InstaswipeyAutoRenewableSubscriptionByYear': {
        price: 45,
        period: 'year',
    }
};

exports.USER_STATUS = {
    0: "Pending",
    1: "Active",
    2: "Reject",
    3: "Block"
}