import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { ClipLoader } from 'react-spinners';
import { Card, CardBody } from "reactstrap";
import { formatDate } from '../../helpers'
import { IMForeignKeyComponent, IMPhoto, IMToggleSwitchComponent } from '../../../common'

import "../../ui/styles.css"

const baseAPIURL = require('../../config').baseAPIURL;

const DetailedSubscriptionsView = (props) => {
    let { subscriptionId } = useParams()

    const [isLoading, setIsLoading] = useState(true);
    const [originalData, setOriginalData] = useState(null)

    useEffect(() => {
        fetch(baseAPIURL + 'subscription/' + subscriptionId)
        .then(response => response.json())
        .catch(err => {
            console.log(err)
            setIsLoading(false)
        })
        .then(data => {
            setOriginalData(data)
            setIsLoading(false)
        });
    }, [subscriptionId]);

    if (isLoading || !originalData) {
        return (
            <div className="sweet-loading card">
                <div className="spinner-container">
                    <ClipLoader
                        className="spinner"
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={isLoading}
                    />
                </div>
            </div> 
        )
    }

    return (
        <Card className="Card FormCard">
            <CardBody>

            {/* Insert all view form fields here */}
            <div className="FormFieldContainer">
                <label className="FormLabel">Product</label>
                <span className="LockedFieldValue">{originalData.productId}</span>
            </div>
    

             <div className="FormFieldContainer">
                <label className="FormLabel">Customer</label>
                <IMForeignKeyComponent id={originalData.userID} apiRouteName="user" titleKey="firstName" />
            </div>
    

            <div className="FormFieldContainer">
                <label className="FormLabel">Subscription period</label>
                <span className="LockedFieldValue">{originalData.subscriptionPeriod}</span>
            </div>
    

            <div className="FormFieldContainer">
                <label className="FormLabel">Transaction Date</label>
                <span className="LockedFieldValue">{originalData.transactionDate && formatDate(originalData.transactionDate)}</span>
            </div>
    

        </CardBody>
        </Card>
    )
}
  
  export default DetailedSubscriptionsView;