import firebase from 'firebase/app';
import 'firebase/auth';
// import 'firebase/firestore';

//enter your web app configuration options
/* const firebaseConfig = {
    apiKey: "AIzaSyAOWHBpPhKoNhcGFKHH_Q_0AtL2gV-imgQ",
    authDomain: "production-a9404.firebaseapp.com",
    databaseURL: "https://production-a9404.firebaseio.com",
    projectId: "production-a9404",
    storageBucket: "production-a9404.appspot.com",
    messagingSenderId: "525472070731",
    appId: "1:525472070731:web:c757787f10140b9bba61ce"
}; */


//anzar's account
/*const firebaseConfig = {
    apiKey: "AIzaSyD1J02QVNVTFzoxjbJFYhR1Hz-i35AaTL4",
    authDomain: "reactdating-bfb6f.firebaseapp.com",
    databaseURL: "https://reactdating-bfb6f-default-rtdb.firebaseio.com",
    projectId: "reactdating-bfb6f",
    storageBucket: "reactdating-bfb6f.appspot.com",
    messagingSenderId: "39134776526",
    appId: "012c3e1e9c4800c9aa0bca88a94252eb595d9024"
};*/


//QA Account
/*const firebaseConfig = {
    apiKey: "AIzaSyDpAEoMYbE_3nn8q6DuD8_ItJidE5isWeI",//Web API Key
    authDomain: "reactdating-b5369.firebaseapp.com",
    databaseURL: "https://reactdating-b5369-default-rtdb.firebaseio.com",
    projectId: "reactdating-b5369",//projectID
    storageBucket: "reactdating-b5369-default-rtdb.appspot.com",
    messagingSenderId: "118084563298",//project number
    appId: "012c3e1e9c4800c9aa0bca88a94252eb595d9024"
};*/


//Product
const firebaseConfig = {
    apiKey: "AIzaSyCv0nFYOYBPlXYBF5KpT58ZuAZ-ZbKT_D4",//Web API Key
    authDomain: "reaime-1eda5.firebaseapp.com",
    databaseURL: "https://reaime-1eda5-default-rtdb.firebaseio.com",
    projectId: "reaime-1eda5",//projectID
    storageBucket: "reaime-1eda5-default-rtdb.appspot.com",
    messagingSenderId: "814590501274",//project number
    appId: "12d3a2f4ab80c5361cd70a2f30c13f06344a2969"//private_key_id from json file
};

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

export { firebase };