import React, { useMemo, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getToken } from "../../../onboarding";


function CompareIdentity(props) {
	const history = useHistory();
	const userStatus = require("../../../admin/config").USER_STATUS;
	const baseAPIURL = require('../../config').baseAPIURL;
	const [data, setData] = useState();
	const [userId, setUserId] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const statusLabel = ["text-inactive", "text-active", "text-reject", "text-block"];
	useEffect( () => {
		/*if (history?.location?.state?.data) {
			setData(history.location.state.data);
		}*/
		if (history?.location?.state?.data?.id) {
			setUserId(history.location.state.data.id);
		}
	},[] )

	useEffect(() => {
		if (userId) {
			fetchUserDetails();
		}
	}, [userId])

	const statusChange = (status) => {
        let statusVal = (userStatus && userStatus[status]) ? userStatus[status] : null;
        if (window.confirm('Are you sure you want to ' + statusVal + " this user?")) {
            updateStatus(status);
		}
    }

	const updateStatus = async (status) => {
        const response = await fetch(baseAPIURL + 'user-status/' + userId, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
				'Authorization': getToken()
            },
            body: JSON.stringify({status: status})
        });
        if (response && response?.status == 200) {//success case
/*			let prevData = data;
			prevData['status'] = parseInt(status);
			setData(prevData);*/
			fetchUserDetails();
			if (status == 1 || status == 2) {
                sendEmailOnActivation(status);
            }
        }
    }

	const sendEmailOnActivation = async (status) => {
        let userName = data?.firstName ? data.firstName : null;
        userName = userName + " " + (data?.lastName ? data.lastName : "");
        let userEmail = data?.email ? data.email : null;
        if (userEmail && userName) {
            const response = await fetch(baseAPIURL + 'email/activation-rejection-email', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
					'Authorization': getToken()
                },
                body: JSON.stringify({userEmail: userEmail, userName: userName, status: status})
            });
		}
    }

    const fetchUserDetails = () => {
        const token = getToken()
        const config = {
            headers: { 'Authorization': token }
        };

        const extraQueryParams = null
        setIsLoading(true)
        fetch(baseAPIURL + "user/" + userId, {
        	headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
				'Authorization': getToken()
            }
        })
            .then(response => response.json())
            .then(data => {
                setData(data);
                setIsLoading(false)
            })
            .catch(err => {
            	console.log(err)
            	setIsLoading(false);
        	});
    }

    const handleImage = (currentTarget) => {
    	currentTarget.onerror = null;
		currentTarget.src = "/imageNotFound.jpg";
		return currentTarget;
    }

	return (
		<div className="content">
			<div className="Card">
				<div className="CardHeader">
					<div className="AddLink">
						<button className='btn btn-sm btn-success' onClick={() => statusChange(1)} disabled={data?.status != 0}>Active</button>
						<button className='btn btn-sm btn-primary' onClick={() => statusChange(2)} disabled={data?.status == 2}>Reject</button>
						<button className='btn btn-sm btn-danger' onClick={() => statusChange(3)} disabled={data?.status == 3}>Block</button>
					</div>
					<h4>Identity Verification {/*data?.status != undefined ? <span> - <span className={statusLabel[data.status]}>{userStatus[data.status]}</span></span> : ""*/}</h4>
				</div>
				<div className="CardBody">
					<div className="user-info">
						<div className="div-block">
							<label>First Name</label>
							{data?.firstName}
						</div>
						<div className="div-block">
							<label>Last Name</label>
							{data?.lastName}
						</div>
						<div className="div-block">
							<label>Email</label>
							{data?.email}
						</div>
						<div className="div-block">
							<label>Current Status</label>
							{data?.status != undefined ? statusLabel[data.status] : "-"}
						</div>
					</div>
					{
						isLoading ? <div className="view-loading">Loading...</div> : 
						<div className="custom-row">
							<div className="custom-col-lg-6 pr-20 pl-20">
								<div className="img-block">
									<img className={data?.profilePictureURL ? "zoomable" : ""} src={data?.profilePictureURL ? data.profilePictureURL : "/imageNotFound.jpg"} alt="ID IMAGE" onError={({ currentTarget }) => handleImage(currentTarget)}/>
								</div>
							</div>
							<div className="custom-col-lg-6">
								<div className="video-block">
									{
										data?.profileVideoURL ? 
										<video width="100%" height="100%" controls>
										  <source src={data?.profileVideoURL} type="video/mp4"/>
										  <source src={data?.profileVideoURL} type="video/ogg"/>
										  Your browser does not support HTML video.
										</video> : <img src="/defaultvideo.jpg" alt="Video not found"/>
									}
								</div>
							</div>
						</div>	
					}
				</div>
			</div>
		</div>
	)
}

export default CompareIdentity;