
import React from 'react';

function HomePage() {
    return (
        <div>This is the admin home page.</div>
    )
}

export default HomePage;
