import React, { useMemo, useEffect, useState } from 'react';
import { IMLocationTableCell, IMImagesTableCell, IMDateTableCell } from '../../ui/IMTable';
import { useTable, usePagination, useFilters } from "react-table";
import {
    Row,
    Col
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { getToken } from "../../../onboarding";

const baseAPIURL = require('../../config').baseAPIURL;
const userStatus = require("../../../admin/config").USER_STATUS;
let timeout;

// Default UI for filter input
function DefaultColumnFilter({
    column: { filterValue, setFilter },
}) {
    return (
        <input
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value || undefined)} // Set undefined to remove the filter entirely
            placeholder={`Search...`}
            className="filter-input"
        />
    );
}

// Dropdown UI for filter
function DropdownColumnFilter({
    column: { filterValue, setFilter },
}) {
    return (
        <select className='filter-select' onChange={e => setFilter(e.target.value || undefined)}>
            <option value="">All</option>
            {
                Object.keys(userStatus).map(v => (
                    <option key={v} value={v}>{userStatus[v]}</option>
                ))
            }
        </select>
    );
}


// disabled filter UI
function DisabledColumnFilter({
    column: { filterValue, setFilter },
}) {
    return (
        <div className='disabled-filter'></div>
    );
}

const usersColumns = (fetchInactiveUserList) => [
    {
        Header: "First Name",
        accessor: "firstName",
        Filter: DefaultColumnFilter, // Attach the filter here
    },
    {
        Header: "Last Name",
        accessor: "lastName",
        Filter: DefaultColumnFilter, // Attach the filter here
    },
    {
        Header: "Email",
        accessor: "email",
        Filter: DefaultColumnFilter, // Attach the filter here
    },
    /* {
        Header: "Location",
        accessor: "location",
        Cell: IMLocationTableCell,
    }, */
    /*{
        Header: "Selfie Picture",
        accessor: "photos",
        Cell: data => (
            <IMImagesTableCell singleImageURL={data.value} />
        )
    },*/
    /* {
        Header: "Selfie Video",
        accessor: "photos",
        Cell: data => (
            <IMImagesTableCell imageURLs={data.value} />
        )
    }, */
    {
        Header: "Actions",
        accessor: "actions",
        Cell: data => (
            <ActionsItemView  data={data} fetchInactiveUserList={fetchInactiveUserList} />
        ),
        Filter: DropdownColumnFilter
        //disableFilters: true // Disable filtering for this column
    },
    {
        Header: "View",
        accessor: "view",
        Cell: data => (
            <CompareIdentity data={data}/>
        ),
        Filter: DisabledColumnFilter
        //disableFilters: true // Disable filtering for this column
    }
]

function ActionsItemView(props) {
    //const { data } = props
    const { data, fetchInactiveUserList } = props;
    const history = useHistory();

    

    const statusChange = (event, item) => {
        let status = parseInt(event.target.value);
        let previousStatus = item.status;
        let statusVal = (userStatus && userStatus[status]) ? userStatus[status] : null;
        if (window.confirm('Are you sure you want to ' + statusVal + " this user?")) {
            updateStatus(item, status);
        } else {
            event.target.value = previousStatus;
        }
    }

    const updateStatus = async (item, status) => {
        let userId = item.id;
        const response = await fetch(baseAPIURL + 'user-status/' + userId, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({status: status})
        });
        if (response && response?.status == 200) {//success case
            if (status == 1 || status == 2) {
                console.log('send email to user for active or rejection')
                sendEmailOnActivation(item, status);
            }
            fetchInactiveUserList();
        }
    }

    const sendEmailOnActivation = async (item, status) => {
        let userName = item?.firstName ? item.firstName : null;
        userName = userName + " " + (item?.lastName ? item.lastName : "");
        let userEmail = item?.email ? item.email : null;
        if (userEmail && userName) {
            const response = await fetch(baseAPIURL + 'email/activation-rejection-email', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({userEmail: userEmail, userName: userName, status: status})
            });
        }
    }

    let currentStatus = data?.row?.original?.status ? data.row.original.status : 0;
    let userId = data?.row?.original?.id ? data.row.original.id : null;
    return (
        <div className="inline-actions-container" key={userId}>
            <select className='select-dropdown' defaultValue={currentStatus} onChange={(e) => statusChange(e, data.row.original)}>
                {
                    Object.keys(userStatus).map(v => (
                        <option key={v} value={v} disabled={v == 0 ? true : false}>{userStatus[v]}</option>
                    ))
                }
            </select>
        </div>
    )
}

function PendingUsers(props) {
    const listName = "pending-users"

    const [isLoading, setIsLoading] = useState(true);
    const [controlledPageCount, setControlledPageCount] = useState(0)
    const [users, setUsers] = useState([]);
    const [data, setData] = useState([]);

    const fetchInactiveUserList = (filters = {}) => {
        const token = getToken()
        const config = {
            headers: { 'Authorization': token }
        };

        /* const extraQueryParams = null */
        const filterQuery = Object.keys(filters)
            .map(key => `${key}=${encodeURIComponent(filters[key])}`)
            .join('&');
        const extraQueryParams = filterQuery ? `?${filterQuery}` : '';
        setIsLoading(true)
        setData([]);
        fetch(baseAPIURL + listName + (extraQueryParams ? extraQueryParams : ""), config)
            .then(response => response.json())
            .then(data => {
                const users = data.users;
                setData(users);
                setIsLoading(false)
            })
            .catch(err => { console.log(err) });
    }

    const columns = useMemo(
        () => usersColumns(fetchInactiveUserList),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        //pagination
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize, filters },
    } = useTable(
        {
            columns,
            data: users,
            initialState: { pageIndex: 0 },
            manualPagination: true,
            manualFilters: true, // Enable manual filtering
            pageCount: controlledPageCount,
        }, 
        useFilters, // Enable filtering
        usePagination
    )

    useEffect(() => {
        fetchInactiveUserList();
    }, []);

    useEffect(() => {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize

        setUsers(data.slice(startRow, endRow))
        setControlledPageCount(Math.ceil(data.length / pageSize))

    }, [pageIndex, pageSize, data])

    useEffect(() => {
        // Fetch data whenever filters change
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fetchInactiveUserList(filters.reduce((acc, filter) => {
                acc[filter.id] = filter.value;
                return acc;
            }, {}));
        }, 400)
    }, [filters]);

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <div className="Card">
                            {/* <div className="CardHeader">
                                <a className="Link AddLink" href="/admin/user/add">Add New</a>
                                <h4>Users</h4>
                            </div> */}
                            <div className="CardBody">
                                <div className="TableContainer">
                                    <table className="Table" {...getTableProps()}>
                                        <thead>
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(column => (
                                                        <th {...column.getHeaderProps()}>
                                                            {column.render('Header')}
                                                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {page.map((row, i) => {
                                                prepareRow(row)
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => {
                                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                {isLoading ? (
                                                    <td colSpan={usersColumns(fetchInactiveUserList).length}>
                                                        <p>
                                                            Loading...
                                                        </p>
                                                    </td>
                                                ) : (
                                                        <td colSpan={usersColumns(fetchInactiveUserList).length}>
                                                            <p className="PaginationDetails">
                                                                Showing {page.length} of {data.length}{' '} results
                                                            </p>
                                                        </td>
                                                    )}
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="Pagination">
                                        <div className="LeftPaginationButtons">
                                            <button onClick={() => gotoPage(0)} className="PaginationButton" disabled={!canPreviousPage}>
                                                <i className="fa fa-angle-double-left"></i>
                                            </button>{' '}
                                            <button onClick={() => previousPage()} className="PaginationButton" disabled={!canPreviousPage}>
                                                <i className="fa fa-angle-left"></i>
                                            </button>
                                        </div>
                                        <div className="CenterPaginationButtons">
                                            <span>
                                                Page{' '}
                                                <strong>
                                                    {pageIndex + 1} of {pageOptions.length}
                                                </strong>{' '}
                                            </span>
                                            <span>
                                                | Go to page:{' '}
                                                <input
                                                    type="number"
                                                    defaultValue={pageIndex + 1}
                                                    onChange={e => {
                                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                                        gotoPage(page)
                                                    }}
                                                    style={{ width: '100px' }}
                                                />
                                            </span>{' '}
                                            <select
                                                value={pageSize}
                                                onChange={e => {
                                                    setPageSize(Number(e.target.value))
                                                }}
                                            >
                                                {[10, 20, 30, 40, 50].map(pageSize => (
                                                    <option key={pageSize} value={pageSize}>
                                                        Show {pageSize}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="RightPaginationButtons">
                                            <button onClick={() => nextPage()} className="PaginationButton" disabled={!canNextPage}>
                                                <i className="fa fa-angle-right"></i>
                                            </button>{' '}
                                            <button onClick={() => gotoPage(pageCount - 1)} className="PaginationButton" disabled={!canNextPage}>
                                                <i className="fa fa-angle-double-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

function CompareIdentity(props) {
    //const { data } = props
    const { data } = props;
    const history = useHistory();

    const compareID = (item) => {
        const editPath = "/admin/compare-identity";
        console.log('data>>', item)
        history.push({
          pathname: editPath,
          state: { data: item }
        });
    }
    return (
            <div className="inline-actions-container">
                <button type="button" className="btn-icon btn btn-info btn-sm" title="Compare Image & Video" onClick={() => compareID(data.row.original)}>
                    <i className="fa fa-picture-o"></i>
                </button>
            </div>
    )

}

export default PendingUsers